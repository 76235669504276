import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../views/shared/layout/layout";
import { SEO } from '../../components/SEO/SEO';
import Hero from "../../views/shared/hero/Hero";
import img from "../../assets/images/hero-home.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title="Aviso Legal | Obelion" description="Aviso Legal de Obelion P&amp;W S.L." robots="noindex nofollow" />
        <Hero title={"Aviso Legal"} photo={img} />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`En aplicación del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (en adelante, el Reglamento general de protección de datos o “RGPD”), así como la normativa de desarrollo que del mismo se pueda derivar, queremos informarle sobre ciertos aspectos relativos al tratamiento de sus datos que llevaremos a cabo como consecuencia de su navegación en nuestra página web, así como los derechos que le asisten. Los datos personales que nos facilita quedan incorporados en un fichero cuyo responsable es Obelion P&W S.L. (en adelante Obelion), con domicilio social en C/ Jose María Lacarra de Miguel – Zaragoza (Zaragoza), 50008 – que será utilizado con la finalidad de gestionar la relación comercial, prestarle los servicios solicitados e informarle sobre nuestros productos y servicios.`}</p>
    <p>{`Obelion no puede asumir ninguna responsabilidad derivada del uso incorrecto, inapropiado o ilícito de la información aparecida en la página de Internet de obelion.org. Con los límites establecidos en la ley, Obelion no asume ninguna responsabilidad derivada de la falta de veracidad, integridad, actualización y precisión de los datos o informaciones que se contienen en sus páginas de Internet. Los contenidos e información no vinculan a Obelion ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo. La página de Internet de Obelion puede contener enlaces (links) a otras páginas de terceras partes que Obelion no puede controlar. Por lo tanto, Obelion no puede asumir responsabilidades por el contenido que pueda aparecer en páginas de terceros.
Los textos, imágenes, sonidos, animaciones, software y el resto de contenidos incluidos en este website son propiedad exclusiva de Obelion o sus licenciantes. Cualquier acto de transmisión, distribución, cesión, reproducción, almacenamiento o comunicación pública total o parcial, debe contar con el consentimiento expreso de Obelion.`}</p>
    <p>{`Ud. podrá ejercitar, respecto de los datos recabados en la forma prevista en el apartado anterior, los derechos reconocidos en la normativa indicada, y en particular los derechos de acceso, rectificación, cancelación y oposición, limitación del tratamiento y portabilidad, así como el de revocación del consentimiento para la cesión de sus datos. Los derechos referidos en el párrafo anterior podrán ser ejercitados mediante solicitud escrita y firmada, acompañando fotocopia de su DNI, a través del correo electrónico: `}<a parentName="p" {...{
        "href": "mailto:info@obelion.org"
      }}>{`info@obelion.org`}</a></p>
    <p>{`Los datos personales contenidos en este fichero serán tratados conforme a las exigencias legales, adoptándose las medidas de seguridad correspondientes para evitar su alteración, pérdida, tratamiento o acceso no autorizado. Se entenderá que los datos facilitados por Ud. son verdaderos, exactos y actualizados, siendo de su responsabilidad las consecuencias que pudiera ocasionar a terceros la aportación de datos falsos o inexactos.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      